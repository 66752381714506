import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

interface IDefaultBreadcrumbProps {
  items?: any[];
}

const DefaultBreadcrumb = (props: IDefaultBreadcrumbProps) => {
  return (
    <Breadcrumb
      style={{ margin: "16px 8px" }}
      items={[{ title: <Link to="/">Dashboard</Link> }, ...(props.items ?? [])]}
    />
  );
};

export default DefaultBreadcrumb;
