import { Form, Row, Col, InputNumber, Select, Button } from "antd";
import { required } from "../../helpers/FormRuleHelper";
import { wageTypeOptions } from "../../pages/WagePage";

const LoyaltyBasedWage = () => {
  return (
    <Form.List name="loyalty">
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((field, index) => (
              <Row
                key={index}
                gutter={[8, 8]}
                align="middle"
                style={{
                  border: "1px solid rgb(19, 25, 32, 0.2)",
                  backgroundColor: "rgb(255,224,179)",
                  padding: "10px 8px 8px 8px",
                  marginBottom: 16,
                  borderRadius: 8,
                }}
              >
                <Col lg={4} xs={10}>
                  <Form.Item
                    name={[field.name, "count"]}
                    label="Tekrar Kaydoluş Sayısı"
                    rules={[required]}
                    initialValue={0}
                  >
                    <InputNumber min={0} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col lg={6} xs={14}>
                  <Form.Item
                    initialValue="amount"
                    name={[field.name, "type"]}
                    label="Aktif Hakediş Tipi"
                    rules={[required]}
                  >
                    <Select options={wageTypeOptions} />
                  </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                  <Form.Item
                    name={[field.name, "amount"]}
                    label="Genel Hakediş Tutarı (₺)"
                    rules={[required]}
                    initialValue="0"
                  >
                    <InputNumber
                      stringMode
                      precision={2}
                      min={0}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                  <Form.Item
                    name={[field.name, "rate"]}
                    label="Genel Hakediş Oranı (%)"
                    rules={[required]}
                    initialValue={0}
                  >
                    <InputNumber min={0} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col lg={2} xs={24}>
                  <Button block onClick={() => remove(field.name)} danger>
                    Kaldır
                  </Button>
                </Col>
              </Row>
            ))}
            <Row>
              <Button
                onClick={() => add()}
                style={{ border: "1px dashed black" }}
              >
                Sadakat Bazlı Hakediş Ekle
              </Button>
            </Row>
          </>
        );
      }}
    </Form.List>
  );
};

export default LoyaltyBasedWage;
