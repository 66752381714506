import { Modal } from "antd";
import { removeCredentials } from "../../helpers/AuthHelper";

export interface ILogoutModalProps {
  open: boolean;
  onClose: () => void;
}

const LogoutModal = (props: ILogoutModalProps) => {
  return (
    <Modal
      title="Çıkış Yap"
      open={props.open}
      onOk={() => removeCredentials()}
      okText="Çıkış Yap"
      okButtonProps={{ danger: true }}
      onCancel={props.onClose}
      cancelText="Vazgeç"
    >
      <p style={{ padding: "10px 0" }}>
        Çıkış yapmak üzeresiniz. Çıkış yapmak istediğinize emin misiniz?
      </p>
    </Modal>
  );
};

export default LogoutModal;
