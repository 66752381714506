import { PaymentCrudApi } from "../../client/EntityApi";
import CrudTable from "../../components/crud/CrudTable";
import {
  getPaymentCPColumns,
  getPaymentCPFormItems,
} from "../../components/hook/PaymentCPComponent";
import DefaultBreadcrumb from "../../components/common/DefaultBreadcrumb";
import { isAdmin } from "../../helpers/AuthHelper";
import CustomCard from "../../components/card/CustomCard";

const PaymentCrudPage = () => {
  return (
    <>
      <DefaultBreadcrumb items={[{ title: "Ödeme Listesi" }]} />
      <CustomCard>
        <CrudTable
          entityLabel="Ödeme"
          api={PaymentCrudApi}
          hideAddProcess={!isAdmin()}
          hideDefaultRowProceses={!isAdmin()}
          columns={getPaymentCPColumns()}
          addFormItems={getPaymentCPFormItems()}
          editFormItems={getPaymentCPFormItems(true)}
          expandable={{
            expandedRowRender: (record) => (
              <p>
                <b>Açıklama:</b> {record.description}
              </p>
            ),
            rowExpandable: (record) => record.description !== undefined,
          }}
        />
      </CustomCard>
    </>
  );
};

export default PaymentCrudPage;
