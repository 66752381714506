import { Button, Col, Collapse, Form, Row, Skeleton, message } from "antd";
import DefaultBreadcrumb from "../components/common/DefaultBreadcrumb";
import GeneralWage from "../components/wage/GeneralWage";
import UserBasedWage from "../components/wage/UserBasedWage";
import ProductBasedWage from "../components/wage/ProductBasedWage";
import LoyaltyBasedWage from "../components/wage/LoyaltyBasedWage";
import { useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { WageApi } from "../client/EntityApi";
import { colors } from "../theme";

export const wageTypeOptions = [
  { label: "Tutar", value: "amount" },
  { label: "Oran", value: "rate" },
];

const WagePage = () => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    WageApi.getWage()
      .then((response) => form.setFieldsValue(response.settings))
      .finally(() => setLoading(false));
  }, [form]);

  return (
    <>
      <DefaultBreadcrumb items={[{ title: "Hakediş Ayarı" }]} />
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          setLoading(true);
          WageApi.editWage({ settings: values })
            .then((response) => {
              message.success(response.message);
              form.setFieldsValue(response.settings);
            })
            .finally(() => setLoading(false));
        }}
      >
        <Skeleton loading={loading} active>
          <Collapse
            style={{ marginBottom: 64 }}
            defaultActiveKey={["1", "2", "3", "4"]}
            items={[
              {
                key: "1",
                label: "Genel Hakediş",
                children: <GeneralWage />,
              },
              {
                key: "2",
                label: "Ürün Bazlı Hakediş",
                children: <ProductBasedWage />,
              },
              {
                key: "3",
                label: "Kullanıcı Bazlı Hakediş",
                children: <UserBasedWage />,
              },
              {
                key: "4",
                label: "Sadakat Bazlı Hakediş",
                children: <LoyaltyBasedWage />,
              },
            ]}
          />
          <Row
            gutter={[8, 8]}
            justify="end"
            style={{
              position: "fixed",
              bottom: 0,
              right: 0,
              borderTop: "1px solid #eee",
              padding: "16px 24px",
              backgroundColor: colors.white,
              width: "100%",
            }}
          >
            <Col>
              <Button
                type="primary"
                danger
                size="middle"
                onClick={() => window.location.reload()}
              >
                Kaydedilmeyenleri Temizle
              </Button>
            </Col>
            <Col>
              <Button type="primary" size="middle" htmlType="submit">
                Kaydet
              </Button>
            </Col>
          </Row>
        </Skeleton>
      </Form>
    </>
  );
};

export default WagePage;
