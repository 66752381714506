import { Button, Col, Form, InputNumber, Row, Select } from "antd";
import { required } from "../../helpers/FormRuleHelper";
import { wageTypeOptions } from "../../pages/WagePage";
import SearchSelect from "../common/SearchSelect";
import { UserCrudApi } from "../../client/EntityApi";
import { getOptionsFromEntities } from "../../helpers/UtilHelper";

const UserBasedWage = () => {
  return (
    <Form.List name="user">
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((field, index) => (
              <Row
                key={index}
                gutter={[8, 8]}
                align="middle"
                style={{
                  border: "1px solid rgb(19, 25, 32, 0.2)",
                  backgroundColor: "rgb(230,238,255)",
                  padding: "10px 8px 8px 8px",
                  marginBottom: 16,
                  borderRadius: 8,
                }}
              >
                <Col lg={3} xs={10}>
                  <Form.Item
                    name={[field.name, "user"]}
                    label="Kullanıcı"
                    rules={[required]}
                  >
                    <SearchSelect
                      labelInValue
                      searchAction={(searchValue, setOptions, setLoading) =>
                        UserCrudApi.getAll(true, 1, 10, searchValue)
                          .then((response) => {
                            setOptions(
                              getOptionsFromEntities(
                                response["hydra:member"],
                                "fullName"
                              )
                            );
                          })
                          .finally(() => setLoading(false))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col lg={6} xs={14}>
                  <Form.Item
                    initialValue="amount"
                    name={[field.name, "type"]}
                    label="Aktif Hakediş Tipi"
                    rules={[required]}
                  >
                    <Select options={wageTypeOptions} />
                  </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                  <Form.Item
                    name={[field.name, "amount"]}
                    label="Genel Hakediş Tutarı (₺)"
                    rules={[required]}
                    initialValue="0"
                  >
                    <InputNumber
                      stringMode
                      precision={2}
                      min={0}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                  <Form.Item
                    name={[field.name, "rate"]}
                    label="Genel Hakediş Oranı (%)"
                    rules={[required]}
                    initialValue={0}
                  >
                    <InputNumber min={0} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col lg={3} xs={24}>
                  <Button block onClick={() => remove(field.name)} danger>
                    Kaldır
                  </Button>
                </Col>
              </Row>
            ))}
            <Row>
              <Button
                onClick={() => add()}
                style={{ border: "1px dashed black" }}
              >
                Kullanıcı Bazlı Hakediş Ekle
              </Button>
            </Row>
          </>
        );
      }}
    </Form.List>
  );
};

export default UserBasedWage;
