import { ProductCrudApi } from "../../client/EntityApi";
import CrudTable from "../../components/crud/CrudTable";
import DefaultBreadcrumb from "../../components/common/DefaultBreadcrumb";
import {
  getProductCPColumns,
  getProductCPFormItems,
} from "../../components/hook/ProductCPComponent";
import CustomCard from "../../components/card/CustomCard";

const ProductCrudPage = () => {
  const formItems = getProductCPFormItems();

  return (
    <>
      <DefaultBreadcrumb items={[{ title: "Ürün Listesi" }]} />
      <CustomCard>
        <CrudTable
          entityLabel="Ürün"
          api={ProductCrudApi}
          columns={getProductCPColumns()}
          addFormItems={formItems}
          editFormItems={formItems}
        />
      </CustomCard>
    </>
  );
};

export default ProductCrudPage;
