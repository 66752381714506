import { Form, Input } from "antd";
import { max, min, required } from "../../helpers/FormRuleHelper";

export const getProductCPColumns = () => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    { title: "İsim", dataIndex: "name", key: "name" },
    { title: "Sentiment ID", dataIndex: "sentimentId", key: "sentimentId" },
  ];
};

export const getProductCPFormItems = () => {
  return (
    <>
      <Form.Item
        name="name"
        label="Ürün Adı"
        rules={[required, min(2), max(255)]}
      >
        <Input placeholder="Ürün Adı" />
      </Form.Item>
      <Form.Item
        name="sentimentId"
        label="Sentiment ID"
        rules={[required, min(1), max(255)]}
      >
        <Input placeholder="Sentiment ID" />
      </Form.Item>
    </>
  );
};
