import { Modal } from "antd";
import { useState } from "react";

interface ICrudTableDeleteModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  refreshData: () => void;
  endpoint: any;
  selectedId?: number;
  setSelectedId: (id?: number) => void;
  entityLabel?: string;
  okText?: string;
  cancelText?: string;
  maskClosable?: boolean;
  deleteModalMessage?: string;
  extendedOnCancel?: () => void;
}

const CrudTableDeleteModal = (props: ICrudTableDeleteModalProps) => {
  const [operationLoading, setOperationLoading] = useState<boolean>(false);

  const onCancel = () => {
    props.setSelectedId(undefined);
    props.setIsOpen(false);
    setOperationLoading(false);
    if (props.extendedOnCancel) props.extendedOnCancel();
  };

  return (
    <Modal
      forceRender
      maskClosable={props.maskClosable ?? false}
      open={props.isOpen}
      onCancel={onCancel}
      title={props.entityLabel ? props.entityLabel + " Sil" : "Sil"}
      okText={props.okText ?? "Sil"}
      cancelText={props.cancelText ?? "Vazgeç"}
      okButtonProps={{ loading: operationLoading }}
      onOk={() => {
        if (props.selectedId) {
          setOperationLoading(true);
          props
            .endpoint(props.selectedId)
            .then(() => props.refreshData())
            .finally(() => onCancel());
        }
      }}
    >
      {props.deleteModalMessage ??
        "Silmek istediğinize emin misiniz? Bu işlem geri alınamaz."}
    </Modal>
  );
};

export default CrudTableDeleteModal;
