import { useContext } from "react";
import { InvoiceCrudApi } from "../../client/EntityApi";
import CrudTable from "../../components/crud/CrudTable";
import { ConstantContext } from "../../contexts";
import DefaultBreadcrumb from "../../components/common/DefaultBreadcrumb";
import {
  getInvoiceCPColumns,
  getInvoiceCPAddFormItems,
  getInvoiceCPEditFormItems,
} from "../../components/hook/InvoiceCPComponent";
import { isAdmin } from "../../helpers/AuthHelper";
import { getFormValue } from "../../components/common/ChunkUpload";
import CustomCard from "../../components/card/CustomCard";

const InvoiceCrudPage = () => {
  const constantContext = useContext(ConstantContext);

  return (
    <>
      <DefaultBreadcrumb items={[{ title: "Fatura Listesi" }]} />
      <CustomCard>
        <CrudTable
          entityLabel="Fatura"
          api={InvoiceCrudApi}
          columns={getInvoiceCPColumns(constantContext?.invoiceStates)}
          hideAddProcess={isAdmin()}
          hideDeleteProcess
          hideEditProcess={!isAdmin()}
          beforeAddOperation={(values) => {
            const media = getFormValue(values.media);
            values.file = Array.isArray(media) ? media[0] : media;
            return values;
          }}
          addFormItems={getInvoiceCPAddFormItems()}
          editFormItems={getInvoiceCPEditFormItems(
            constantContext?.invoiceStates
          )}
        />
      </CustomCard>
    </>
  );
};

export default InvoiceCrudPage;
