import Icon from "@ant-design/icons";

const LiraIcon = (props: any) => {
  return (
    <Icon
      {...props}
      component={() => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          width="1em"
          height="1em"
        >
          <path
            fill="#2ca87f"
            d="M372 256h-48C317.6 256 312 260.9 312 267.2 312 368 230.2 416 144 416V256.8l134.6-29.9A12 12 0 0 0 288 215.2v-41c0-7.7-7.1-13.4-14.6-11.7L144 191.2V160.8l134.6-29.9A12 12 0 0 0 288 119.2V78.2c0-7.7-7.1-13.4-14.6-11.7L144 95.2V44c0-6.6-5.4-12-12-12H76c-6.6 0-12 5.4-12 12v69L9.4 125.1A12 12 0 0 0 0 136.8v41c0 7.7 7.1 13.4 14.6 11.7L64 178.6v30.4L9.4 221.1A12 12 0 0 0 0 232.8v41c0 7.7 7.1 13.4 14.6 11.7L64 274.6V468c0 6.6 5.4 12 12 12h79.6c134.1 0 223.3-77.8 228.4-211.6 .3-6.8-5.2-12.4-12-12.4z"
          />
        </svg>
      )}
    />
  );
};

export default LiraIcon;
