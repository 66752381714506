import {
  Button,
  Col,
  DatePicker,
  Row,
  Skeleton,
  Tooltip,
  Typography,
  Watermark,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import DefaultBreadcrumb from "../components/common/DefaultBreadcrumb";
import { getUser, isAdmin } from "../helpers/AuthHelper";
import ChartCard from "../components/card/ChartCard";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";
import IconCard from "../components/card/IconCard";
import { useCallback, useContext, useEffect, useState } from "react";
import { getIconContainer, GraphicExtra } from "./DashboardPage";
import { colors } from "../theme";
import GoalIcon from "../components/icon/GoalIcon";
import { EyeOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { getConstantValue, getFormattedDate } from "../helpers/UtilHelper";
import CreditCardIcon from "../components/icon/CreditCardIcon";
import LiraIcon from "../components/icon/LiraIcon";
import CalendarIcon from "../components/icon/CalendarIcon";
import { CouponCrudApi, ReportApi, UserCrudApi } from "../client/EntityApi";
import CustomCard from "../components/card/CustomCard";
import CouponCard from "../components/card/CouponCard";
import { ConstantContext } from "../contexts";

const { RangePicker } = DatePicker;
const { Title, Text } = Typography;

const UserDetailPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const constants = useContext(ConstantContext);

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [userLoading, setUserLoading] = useState(false);
  const [userData, setUserData] = useState<any>();
  const [coupons, setCoupons] = useState<any>([]);
  const [couponLoading, setCouponLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pieDataLoading, setPieDataLoading] = useState(false);
  const [barDataLoading, setBarDataLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [pieData, setPieData] = useState<any>();
  const [barData, setBarData] = useState<any>();

  const [pieDate, setPieDate] = useState<any>();
  const [barDate, setBarDate] = useState<any>();

  const getPieData = useCallback(() => {
    setPieDataLoading(true);
    ReportApi.pieDashboardData(
      pieDate?.[0] ?? null,
      pieDate?.[1] ?? null,
      params.id
    )
      .then((response) => setPieData(response))
      .finally(() => setPieDataLoading(false));
  }, [pieDate, params.id]);

  const getBarData = useCallback(() => {
    setBarDataLoading(true);
    ReportApi.barDashboardData(
      barDate?.[0] ?? null,
      barDate?.[1] ?? null,
      params.id
    )
      .then((response) => setBarData(response))
      .finally(() => setBarDataLoading(false));
  }, [barDate, params.id]);

  useEffect(() => {
    setLoading(true);
    ReportApi.dashboardData(params.id)
      .then((response) => setData(response))
      .finally(() => setLoading(false));
  }, [params.id]);

  useEffect(() => {
    getPieData();
  }, [getPieData]);

  useEffect(() => {
    getBarData();
  }, [getBarData]);

  useEffect(() => {
    if (isAdmin()) {
      if (params.id) {
        setUserLoading(true);
        UserCrudApi.get(parseInt(params.id))
          .then((response) => setUserData(response))
          .finally(() => setUserLoading(false));
      }
    } else {
      setUserData(getUser());
    }
  }, [params.id]);

  useEffect(() => {
    setCouponLoading(true);
    CouponCrudApi.getAll(
      true,
      0,
      3,
      undefined,
      ["order[createdAt]=desc"],
      ["user.id=" + params.id]
    )
      .then((response) => setCoupons(response["hydra:member"]))
      .finally(() => setCouponLoading(false));
  }, [params.id]);

  const CustomBarTooltip = (bar: any) => {
    const { id, data } = bar;
    return (
      <div
        style={{
          padding: "5px",
          background: "white",
          border: "1px solid #ccc",
        }}
      >
        <span>{id}: </span>
        <strong>{data[id]}</strong>
      </div>
    );
  };

  return (
    <>
      {isAdmin() && (
        <DefaultBreadcrumb
          items={[
            { title: <Link to="/kullanicilar">Kullanıcı Listesi</Link> },
            { title: "Kullanıcı Detayı" },
          ]}
        />
      )}
      <Row>
        <Col
          span={24}
          onMouseEnter={() => setHoveredIndex(1)}
          onMouseLeave={() => setHoveredIndex(null)}
          className={hoveredIndex === 1 ? "rp-slide-effect-card" : ""}
        >
          <CustomCard
            title={
              <Title level={5} style={{ color: colors.black }}>
                Kullanıcı Bilgileri
              </Title>
            }
            className="colored-card"
            style={{
              background: "linear-gradient(to left, white, #eee)",
              color: colors.black,
            }}
          >
            <Skeleton loading={userLoading} active>
              <Row gutter={[8, 8]}>
                <Col sm={12} xs={24}>
                  <Text strong> {userData?.fullName}</Text>
                </Col>
                <Col sm={12} xs={24}>
                  <Text strong>{userData?.email}</Text>
                </Col>
                <Col sm={12} xs={24}>
                  <Text strong>
                    Rol:{" "}
                    {getConstantValue(userData?.roles[0], constants?.roles)}
                  </Text>
                </Col>
                <Col sm={12} xs={24}>
                  <Text strong>
                    {getFormattedDate(userData?.createdAt)} -{" "}
                    {userData?.isActive === true ? (
                      <Text style={{ color: colors.successColor }}>
                        Aktif Hesap
                      </Text>
                    ) : (
                      <Text style={{ color: colors.errorColor }}>
                        Pasif Hesap
                      </Text>
                    )}
                  </Text>
                </Col>
              </Row>
            </Skeleton>
          </CustomCard>
        </Col>
      </Row>
      <Row style={{ paddingTop: 24 }}>
        <Col span={24}>
          <CustomCard
            title="Son Kuponlarım"
            extra={
              <Button type="link" onClick={() => navigate("/kuponlar")}>
                Tüm Kuponlarım
              </Button>
            }
          >
            <Row gutter={[24, 24]}>
              <Skeleton loading={couponLoading} active>
                {coupons.length > 0
                  ? coupons.map((coupon: any, index: any) => (
                      <CouponCard
                        key={index}
                        hoveredIndex={index}
                        bgColor={
                          index === 0
                            ? colors.primary
                            : index === 1
                            ? colors.infoColor
                            : colors.warningColor
                        }
                        couponCode={coupon.code}
                        clickCount={coupon.clickCount}
                        registerCount={coupon.recordCount}
                        payment={coupon.balance}
                        limit={coupon.useLimit ?? "Limitsiz"}
                        expirationDate={
                          coupon.finishDate
                            ? getFormattedDate(coupon.finishDate)
                            : "Süresiz"
                        }
                      />
                    ))
                  : "Henüz bir kuponunuz bulunmamaktadır."}
              </Skeleton>
            </Row>
          </CustomCard>
        </Col>
      </Row>
      <Row gutter={[24, 24]} style={{ paddingTop: 24 }}>
        <Col lg={12} xs={24}>
          <ChartCard
            loading={pieDataLoading}
            title="Toplam Başarı Oranım"
            actions={[
              <RangePicker
                value={pieDate}
                onChange={(dates) => setPieDate(dates)}
              />,
            ]}
            extra={GraphicExtra}
          >
            {pieData?.pie?.[0].value + pieData?.pie?.[1].value > 0 ? (
              <ResponsivePie
                data={pieData?.pie ?? []}
                colors={[colors.warningColor, colors.successColor]}
                enableArcLinkLabels={false}
                innerRadius={0.5}
                padAngle={1}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                margin={{ left: 24, right: 24, top: 24, bottom: 32 }}
                arcLabelsTextColor={{
                  from: "color",
                  modifiers: [["brighter", 10]],
                }}
                layers={[
                  "arcs",
                  "arcLabels",
                  "arcLinkLabels",
                  "legends",
                  ({ centerX, centerY }) => (
                    <Tooltip title="Başarı Oranı">
                      <text
                        x={centerX}
                        y={centerY}
                        textAnchor="middle"
                        dominantBaseline="central"
                        style={{
                          fontSize: "24px",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                      >
                        %{pieData?.successRate ?? 0}
                      </text>
                    </Tooltip>
                  ),
                ]}
                legends={[
                  {
                    anchor: "bottom",
                    direction: "row",
                    itemWidth: 100,
                    itemHeight: 16,
                    itemsSpacing: 16,
                    symbolSize: 14,
                    symbolShape: "circle",
                    translateY: 32,
                    itemTextColor: "#999",
                    itemOpacity: 1,
                    effects: [
                      { on: "hover", style: { itemTextColor: "#000" } },
                    ],
                  },
                ]}
              />
            ) : (
              <>
                <Watermark height={30} width={120} content="Sentiment Algo">
                  <Row align="middle" justify="center" style={{ height: 500 }}>
                    <Title level={4} style={{ textAlign: "center" }}>
                      Seçili Tarih Aralığına Ait Veri Bulunamadı.
                    </Title>
                  </Row>
                </Watermark>
              </>
            )}
          </ChartCard>
        </Col>
        <Col lg={12} xs={24}>
          <ChartCard
            loading={barDataLoading}
            title="Toplam Ödemeler"
            actions={[
              <RangePicker
                value={barDate}
                onChange={(dates) => setBarDate(dates)}
              />,
            ]}
            extra={GraphicExtra}
          >
            {barData?.[0]?.["Hakediş"] + barData?.[0]?.["Ödenen Tutar"] > 0 ? (
              <ResponsiveBar
                groupMode="grouped"
                keys={["Hakediş", "Ödenen Tutar"]}
                data={barData ?? []}
                margin={{ top: 24, right: 24, bottom: 42, left: 32 }}
                padding={0.3}
                innerPadding={10}
                colors={[colors.secondaryColor, colors.successColor]}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: "color",
                  modifiers: [["brighter", 10]],
                }}
                axisBottom={null}
                legends={[
                  {
                    dataFrom: "keys",
                    anchor: "bottom",
                    direction: "row",
                    justify: false,
                    symbolShape: "circle",
                    translateY: 42,
                    itemWidth: 100,
                    itemHeight: 16,
                    itemOpacity: 0.85,
                    symbolSize: 14,
                    itemTextColor: "#999",
                    effects: [
                      { on: "hover", style: { itemTextColor: "#000" } },
                    ],
                  },
                ]}
                tooltip={CustomBarTooltip}
              />
            ) : (
              <>
                <Watermark height={30} width={120} content="Sentiment Algo">
                  <Row align="middle" justify="center" style={{ height: 500 }}>
                    <Title level={4} style={{ textAlign: "center" }}>
                      Seçili Tarih Aralığına Ait Veri Bulunamadı.
                    </Title>
                  </Row>
                </Watermark>
              </>
            )}
          </ChartCard>
        </Col>
      </Row>
      <Row style={{ paddingTop: 24 }} gutter={[24, 24]}>
        <Col
          xl={8}
          lg={12}
          xs={24}
          style={{ display: "grid" }}
          onMouseEnter={() => setHoveredIndex(2)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <IconCard
            loading={loading}
            topNum={data?.totalAmount + " ₺"}
            bottomText="Toplam Bakiye"
            iconSpan={4}
            icon={getIconContainer(
              colors.successColor,
              <GoalIcon
                style={{
                  fontSize: hoveredIndex === 2 ? "32px" : "28px",
                  transition: "all 0.2s ease-in-out 0s",
                  color: colors.successColor,
                }}
              />
            )}
          />
        </Col>
        <Col
          xl={8}
          lg={12}
          xs={24}
          style={{ display: "grid" }}
          onMouseEnter={() => setHoveredIndex(3)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <IconCard
            loading={loading}
            topNum={data?.totalPayment + " ₺"}
            bottomText="Toplam Alınan Ödeme"
            iconSpan={4}
            icon={getIconContainer(
              colors.primary,
              <EyeOutlined
                style={{
                  fontSize: hoveredIndex === 3 ? 32 : 28,
                  transition: "all 0.2s ease-in-out 0s",
                  color: colors.primary,
                }}
              />
            )}
          />
        </Col>
        <Col
          xl={8}
          lg={12}
          xs={24}
          style={{ display: "grid" }}
          onMouseEnter={() => setHoveredIndex(4)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <IconCard
            loading={loading}
            topNum={
              data?.lastPaymentDate
                ? getFormattedDate(data?.lastPaymentDate)
                : "-"
            }
            bottomText="Son Ödeme Alınan Tarih"
            icon={getIconContainer(
              colors.warningColor,
              <ClockCircleOutlined
                style={{
                  fontSize: hoveredIndex === 4 ? 32 : 28,
                  transition: "all 0.2s ease-in-out 0s",
                  color: colors.warningColor,
                }}
              />
            )}
            iconSpan={4}
          />
        </Col>
      </Row>
      <Row style={{ paddingTop: 24 }} gutter={[24, 24]}>
        <Col
          xl={8}
          lg={12}
          xs={24}
          style={{ display: "grid" }}
          onMouseEnter={() => setHoveredIndex(5)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <IconCard
            loading={loading}
            topText="Toplam Kupon Sayım"
            bottomNum={data?.totalCouponCount ? data?.totalCouponCount : "0"}
            icon={
              <CreditCardIcon
                style={{
                  fontSize: hoveredIndex === 5 ? "44px" : "48px",
                  transition: "all 0.2s ease-in-out 0s",
                }}
              />
            }
            iconSpan={4}
          />
        </Col>
        <Col
          xl={8}
          lg={12}
          xs={24}
          style={{ display: "grid" }}
          onMouseEnter={() => setHoveredIndex(6)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <IconCard
            loading={loading}
            topText="Aktif Kupon Sayım"
            bottomNum={
              data?.totalActiveCouponCount ? data?.totalActiveCouponCount : "0"
            }
            icon={
              <LiraIcon
                style={{
                  fontSize: hoveredIndex === 6 ? "26px" : "30px",
                  transition: "all 0.2s ease-in-out 0s",
                }}
              />
            }
            iconSpan={4}
          />
        </Col>
        <Col
          xl={8}
          lg={12}
          xs={24}
          style={{ display: "grid" }}
          onMouseEnter={() => setHoveredIndex(7)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <IconCard
            loading={loading}
            topText="Pasif Kupon Sayım"
            bottomNum={
              data?.totalPassiveCouponCount
                ? data?.totalPassiveCouponCount
                : "0"
            }
            icon={
              <CalendarIcon
                style={{
                  fontSize: hoveredIndex === 7 ? "40px" : "44px",
                  transition: "all 0.2s ease-in-out 0s",
                }}
              />
            }
            iconSpan={4}
          />
        </Col>
      </Row>
    </>
  );
};

export default UserDetailPage;
