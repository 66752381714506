import { Form, FormInstance, Input, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { forwardRef, useImperativeHandle, useState } from "react";
import { required } from "../../helpers/FormRuleHelper";
import { PaymentCrudApi } from "../../client/EntityApi";
import { getPaymentDefaultFormItems } from "../hook/PaymentCPComponent";

export interface IPaymentAddModalRefMethods {
  setOpen: (open: boolean) => void;
  form: FormInstance;
}

export interface IPaymentAddModalProps {
  afterCreate?: () => void;
}

const PaymentAddModal = forwardRef((props: IPaymentAddModalProps, ref) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    setOpen: setOpen,
    form: form,
  }));

  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Ödeme Oluştur"
      open={open}
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={() =>
        form.validateFields().then((values) => {
          setLoading(true);
          PaymentCrudApi.create(values)
            .then(() => {
              props.afterCreate && props.afterCreate();
              onCancel();
            })
            .finally(() => setLoading(false));
        })
      }
      cancelText="Vazgeç"
      okText="Oluştur"
    >
      <Form form={form} layout="vertical">
        <Form.Item name="user" rules={[required]} hidden>
          <Input />
        </Form.Item>
        {getPaymentDefaultFormItems()}
      </Form>
    </Modal>
  );
});

export default PaymentAddModal;
