import { RouterProvider, createBrowserRouter } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import AdminLayout from "./components/layouts/AdminLayout";
import LoginRoute from "./components/common/LoginRoute";
import PrivateRoute from "./components/common/PrivateRoute";
import UserCrudPage from "./pages/crud/UserCrudPage";
import DashboardPage from "./pages/DashboardPage";
import { ConfigProvider } from "antd";
import locale from "antd/locale/tr_TR";
import "dayjs/locale/tr";
import UserDetailPage from "./pages/UserDetailPage";
import CouponCrudPage from "./pages/crud/CouponCrudPage";
import RecordCrudPage from "./pages/crud/RecordCrudPage";
import PaymentCrudPage from "./pages/crud/PaymentCrudPage";
import InvoiceCrudPage from "./pages/crud/InvoiceCrudPage";
import { isAdmin } from "./helpers/AuthHelper";
import NotFoundPage from "./pages/NotFoundPage";
import WagePage from "./pages/WagePage";
import ProductCrudPage from "./pages/crud/ProductCrudPage";
import { componentsToken, seedToken } from "./theme";
import LabelCrudPage from "./pages/crud/LabelCrudPage";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <PrivateRoute loginPath="giris" layout={AdminLayout} />,
      children: [
        {
          path: "/",
          element: isAdmin() ? <DashboardPage /> : <UserDetailPage />,
        },
        isAdmin() && { path: "/kullanicilar", element: <UserCrudPage /> },
        isAdmin() && { path: "/kullanicilar/:id", element: <UserDetailPage /> },
        { path: "/kuponlar", element: <CouponCrudPage /> },
        { path: "/kayitlar", element: <RecordCrudPage /> },
        { path: "/odemeler", element: <PaymentCrudPage /> },
        { path: "/faturalar", element: <InvoiceCrudPage /> },
        isAdmin() && { path: "/etiketler", element: <LabelCrudPage /> },
        isAdmin() && { path: "/urunler", element: <ProductCrudPage /> },
        isAdmin() && { path: "/hakedis", element: <WagePage /> },
      ],
    },
    {
      path: "/giris",
      element: <LoginRoute element={<LoginPage />} />,
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ]);

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: seedToken,
        components: componentsToken,
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
};

export default App;
