import IconCard from "../components/card/IconCard";
import { Col, DatePicker, Row, Tooltip, Typography, Watermark } from "antd";
import { useCallback, useEffect, useState } from "react";
import { ReportApi } from "../client/EntityApi";
import { getFormattedDate } from "../helpers/UtilHelper";
import ChartCard from "../components/card/ChartCard";
import { ResponsivePie } from "@nivo/pie";
import { colors } from "../theme";
import { ResponsiveBar } from "@nivo/bar";
import { TinyColor } from "@ctrl/tinycolor";
import GoalIcon from "../components/icon/GoalIcon";
import {
  EyeOutlined,
  ClockCircleOutlined,
  QuestionCircleTwoTone,
} from "@ant-design/icons";
import CreditCardIcon from "../components/icon/CreditCardIcon";
import LiraIcon from "../components/icon/LiraIcon";
import CalendarIcon from "../components/icon/CalendarIcon";

const { RangePicker } = DatePicker;
const { Title } = Typography;

const getTinyColor = (color: string) =>
  new TinyColor(color).lighten(40).toString();

export const getIconContainer = (color: string, icon?: any) => (
  <Row
    justify="center"
    align="middle"
    style={{
      width: 52,
      height: 52,
      borderRadius: 8,
      backgroundColor: getTinyColor(color),
      border: `1px solid ${getTinyColor(color)}`,
    }}
  >
    {icon}
  </Row>
);

export const GraphicExtra = (
  <Tooltip
    title="Grafiğin alt kısmından bir zaman aralığı seçilmediyse, tüm zamanların verileri gösterilir."
    arrow={false}
  >
    <QuestionCircleTwoTone style={{ fontSize: 18 }} />
  </Tooltip>
);

const DashboardPage = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [pieDataLoading, setPieDataLoading] = useState(false);
  const [barDataLoading, setBarDataLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [pieData, setPieData] = useState<any>();
  const [barData, setBarData] = useState<any>();

  const [pieDate, setPieDate] = useState<any>();
  const [barDate, setBarDate] = useState<any>();

  const getPieData = useCallback(() => {
    setPieDataLoading(true);
    ReportApi.pieDashboardData(pieDate?.[0] ?? null, pieDate?.[1] ?? null)
      .then((response) => setPieData(response))
      .finally(() => setPieDataLoading(false));
  }, [pieDate]);

  const getBarData = useCallback(() => {
    setBarDataLoading(true);
    ReportApi.barDashboardData(barDate?.[0] ?? null, barDate?.[1] ?? null)
      .then((response) => setBarData(response))
      .finally(() => setBarDataLoading(false));
  }, [barDate]);

  useEffect(() => {
    setLoading(true);
    ReportApi.dashboardData()
      .then((response) => setData(response))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getPieData();
  }, [getPieData]);

  useEffect(() => {
    getBarData();
  }, [getBarData]);

  const CustomBarTooltip = (bar: any) => {
    const { id, data } = bar;
    return (
      <div
        style={{
          padding: "5px",
          background: "white",
          border: "1px solid #ccc",
        }}
      >
        <span>{id}: </span>
        <strong>{data[id]}</strong>
      </div>
    );
  };

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col
          xl={8}
          lg={24}
          xs={24}
          style={{ display: "grid" }}
          onMouseEnter={() => setHoveredIndex(1)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <IconCard
            loading={loading}
            topNum={data?.totalAmount + " ₺"}
            bottomText="Toplam Hakediş"
            iconSpan={4}
            icon={getIconContainer(
              colors.successColor,
              <GoalIcon
                style={{
                  fontSize: hoveredIndex === 1 ? "32px" : "28px",
                  transition: "all 0.2s ease-in-out 0s",
                  color: colors.successColor,
                }}
              />
            )}
          />
        </Col>
        <Col
          xl={8}
          lg={12}
          xs={24}
          style={{ display: "grid" }}
          onMouseEnter={() => setHoveredIndex(2)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <IconCard
            loading={loading}
            topNum={data?.totalPayment + " ₺"}
            bottomText="Toplam Ödenen Tutar"
            iconSpan={4}
            icon={getIconContainer(
              colors.primary,
              <EyeOutlined
                style={{
                  fontSize: hoveredIndex === 2 ? 32 : 28,
                  transition: "all 0.2s ease-in-out 0s",
                  color: colors.primary,
                }}
              />
            )}
          />
        </Col>
        <Col
          xl={8}
          lg={12}
          xs={24}
          style={{ display: "grid" }}
          onMouseEnter={() => setHoveredIndex(3)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <IconCard
            loading={loading}
            topNum={
              data?.lastPaymentDate
                ? getFormattedDate(data?.lastPaymentDate)
                : "-"
            }
            bottomText="Son Ödeme Tarihi"
            icon={getIconContainer(
              colors.warningColor,
              <ClockCircleOutlined
                style={{
                  fontSize: hoveredIndex === 3 ? 32 : 28,
                  transition: "all 0.2s ease-in-out 0s",
                  color: colors.warningColor,
                }}
              />
            )}
            iconSpan={4}
          />
        </Col>
      </Row>
      <Row gutter={[24, 24]} style={{ paddingTop: 24 }}>
        <Col lg={12} xs={24}>
          <ChartCard
            loading={pieDataLoading}
            title="Toplam Başarı Oranı"
            actions={[
              <RangePicker
                value={pieDate}
                onChange={(dates) => setPieDate(dates)}
              />,
            ]}
            extra={GraphicExtra}
          >
            {pieData?.pie?.[0].value + pieData?.pie?.[1].value > 0 ? (
              <ResponsivePie
                data={pieData?.pie ?? []}
                colors={[colors.warningColor, colors.successColor]}
                enableArcLinkLabels={false}
                innerRadius={0.5}
                padAngle={1}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                margin={{ left: 24, right: 24, top: 24, bottom: 32 }}
                arcLabelsTextColor={{
                  from: "color",
                  modifiers: [["brighter", 10]],
                }}
                layers={[
                  "arcs",
                  "arcLabels",
                  "arcLinkLabels",
                  "legends",
                  ({ centerX, centerY }) => (
                    <Tooltip title="Başarı Oranı">
                      <text
                        x={centerX}
                        y={centerY}
                        textAnchor="middle"
                        dominantBaseline="central"
                        style={{
                          fontSize: "24px",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                      >
                        %{pieData?.successRate ?? 0}
                      </text>
                    </Tooltip>
                  ),
                ]}
                legends={[
                  {
                    anchor: "bottom",
                    direction: "row",
                    itemWidth: 100,
                    itemHeight: 16,
                    itemsSpacing: 16,
                    symbolSize: 14,
                    symbolShape: "circle",
                    translateY: 32,
                    itemTextColor: "#999",
                    itemOpacity: 1,
                    effects: [
                      { on: "hover", style: { itemTextColor: "#000" } },
                    ],
                  },
                ]}
              />
            ) : (
              <>
                <Watermark height={30} width={120} content="Sentiment Algo">
                  <Row align="middle" justify="center" style={{ height: 500 }}>
                    <Title level={4} style={{ textAlign: "center" }}>
                      Seçili Tarih Aralığına Ait Veri Bulunamadı.
                    </Title>
                  </Row>
                </Watermark>
              </>
            )}
          </ChartCard>
        </Col>
        <Col lg={12} xs={24}>
          <ChartCard
            loading={barDataLoading}
            title="Toplam Ödemeler"
            actions={[
              <RangePicker
                value={barDate}
                onChange={(dates) => setBarDate(dates)}
              />,
            ]}
            extra={GraphicExtra}
          >
            {barData?.[0]?.["Hakediş"] + barData?.[0]?.["Ödenen Tutar"] > 0 ? (
              <ResponsiveBar
                groupMode="grouped"
                keys={["Hakediş", "Ödenen Tutar"]}
                data={barData ?? []}
                margin={{ top: 24, right: 24, bottom: 42, left: 32 }}
                padding={0.3}
                innerPadding={10}
                colors={[colors.secondaryColor, colors.successColor]}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: "color",
                  modifiers: [["brighter", 10]],
                }}
                axisBottom={null}
                legends={[
                  {
                    dataFrom: "keys",
                    anchor: "bottom",
                    direction: "row",
                    justify: false,
                    symbolShape: "circle",
                    translateY: 42,
                    itemWidth: 100,
                    itemHeight: 16,
                    itemOpacity: 0.85,
                    symbolSize: 14,
                    itemTextColor: "#999",
                    effects: [
                      { on: "hover", style: { itemTextColor: "#000" } },
                    ],
                  },
                ]}
                tooltip={CustomBarTooltip}
              />
            ) : (
              <>
                <Watermark height={30} width={120} content="Sentiment Algo">
                  <Row align="middle" justify="center" style={{ height: 500 }}>
                    <Title level={4} style={{ textAlign: "center" }}>
                      Seçili Tarih Aralığına Ait Veri Bulunamadı.
                    </Title>
                  </Row>
                </Watermark>
              </>
            )}
          </ChartCard>
        </Col>
      </Row>
      <Row style={{ paddingTop: 24 }} gutter={[24, 24]}>
        <Col
          xl={8}
          lg={24}
          xs={24}
          style={{ display: "grid" }}
          onMouseEnter={() => setHoveredIndex(4)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <IconCard
            loading={loading}
            topText="Oluşturulan Kupon Saysı"
            bottomNum={data?.totalCouponCount ? data?.totalCouponCount : "0"}
            icon={
              <CreditCardIcon
                style={{
                  fontSize: hoveredIndex === 4 ? "44px" : "48px",
                  transition: "all 0.2s ease-in-out 0s",
                }}
              />
            }
            iconSpan={4}
          />
        </Col>
        <Col
          xl={8}
          lg={12}
          xs={24}
          style={{ display: "grid" }}
          onMouseEnter={() => setHoveredIndex(5)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <IconCard
            loading={loading}
            topText="Aktif Kupon Sayısı"
            bottomNum={
              data?.totalActiveCouponCount ? data?.totalActiveCouponCount : "0"
            }
            icon={
              <LiraIcon
                style={{
                  fontSize: hoveredIndex === 5 ? "26px" : "30px",
                  transition: "all 0.2s ease-in-out 0s",
                }}
              />
            }
            iconSpan={4}
          />
        </Col>
        <Col
          xl={8}
          lg={12}
          xs={24}
          style={{ display: "grid" }}
          onMouseEnter={() => setHoveredIndex(6)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <IconCard
            loading={loading}
            topText="Pasif Kupon Sayısı"
            bottomNum={
              data?.totalPassiveCouponCount
                ? data?.totalPassiveCouponCount
                : "0"
            }
            icon={
              <CalendarIcon
                style={{
                  fontSize: hoveredIndex === 6 ? "40px" : "42px",
                  transition: "all 0.2s ease-in-out 0s",
                }}
              />
            }
            iconSpan={4}
          />
        </Col>
      </Row>
    </>
  );
};

export default DashboardPage;
