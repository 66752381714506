import { LabelCrudApi } from "../../client/EntityApi";
import CrudTable from "../../components/crud/CrudTable";
import DefaultBreadcrumb from "../../components/common/DefaultBreadcrumb";
import CustomCard from "../../components/card/CustomCard";
import {
  getLabelCPColumns,
  getLabelCPFormItems,
} from "../../components/hook/LabelCPComponent";

const LabelCrudPage = () => {
  return (
    <>
      <DefaultBreadcrumb items={[{ title: "Etiket Listesi" }]} />
      <CustomCard>
        <CrudTable
          entityLabel="Etiket"
          api={LabelCrudApi}
          columns={getLabelCPColumns()}
          addFormItems={getLabelCPFormItems()}
          editFormItems={getLabelCPFormItems(true)}
        />
      </CustomCard>
    </>
  );
};

export default LabelCrudPage;
