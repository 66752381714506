import { Col, Row, Skeleton, Typography } from "antd";
import CustomCard from "./CustomCard";

const { Title, Text } = Typography;

interface IIconCardProps {
  topText?: any;
  topNum?: any;
  bottomText?: any;
  bottomNum?: any;
  icon?: any;
  iconSpan?: any;
  loading?: any;
}

const IconCard = (props: IIconCardProps) => {
  return (
    <CustomCard
      style={{
        height: "100%",
        alignContent: "center",
      }}
    >
      <Skeleton loading={props.loading} active>
        <Row align="middle" justify="space-between">
          <Col span={24 - props.iconSpan}>
            <Row>
              <Col span={24} style={{ paddingBottom: 8 }}>
                {props.topNum ? (
                  <Title
                    level={2}
                    style={{
                      margin: 0,
                    }}
                  >
                    {props.topNum}
                  </Title>
                ) : props.topText ? (
                  <Text>{props.topText}</Text>
                ) : (
                  <></>
                )}
              </Col>
              <Col span={24}>
                {props.bottomText ? (
                  <Text>{props.bottomText}</Text>
                ) : props.bottomNum ? (
                  <Title
                    level={2}
                    style={{
                      margin: 0,
                    }}
                  >
                    {props.bottomNum}
                  </Title>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={props.iconSpan}>{props.icon}</Col>
        </Row>
      </Skeleton>
    </CustomCard>
  );
};

export default IconCard;
