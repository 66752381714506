import { Form, Input } from "antd";
import { max, min, required } from "../../helpers/FormRuleHelper";

export const getLabelCPColumns = () => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    { title: "Etiket Adı", dataIndex: "name", key: "name" },
    { title: "Kupon Sayısı", dataIndex: "couponCount", key: "couponCount" },
  ];
};

export const getLabelCPFormItems = (isEdit = false) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        name="name"
        label="Etiket Adı"
        rules={[required, min(2), max(30)]}
      >
        <Input placeholder="Etiket Adı" />
      </Form.Item>
    </>
  );
};
