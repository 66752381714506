import { Form, FormInstance, Modal } from "antd";
import { ReactNode, useState } from "react";

interface ICrudTableEditModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  refreshData: () => void;
  endpoint: any;
  selectedId?: number;
  setSelectedId: (id?: number) => void;
  entityLabel?: string;
  okText?: string;
  cancelText?: string;
  maskClosable?: boolean;
  formInstance: FormInstance;
  formItems?: ReactNode;
  width?: string | number;
  beforeOperation?: (values: any) => any;
  afterOperation?: () => void;
  extendedOnCancel?: () => void;
}

const CrudTableEditModal = (props: ICrudTableEditModalProps) => {
  const [operationLoading, setOperationLoading] = useState<boolean>(false);

  const onCancel = () => {
    props.setSelectedId(undefined);
    props.formInstance.resetFields();
    props.setIsOpen(false);
    setOperationLoading(false);
    if (props.extendedOnCancel) props.extendedOnCancel();
  };

  return (
    <Modal
      forceRender
      width={props.width}
      maskClosable={props.maskClosable ?? false}
      open={props.isOpen}
      onCancel={onCancel}
      title={props.entityLabel ? props.entityLabel + " Düzenle" : "Düzenle"}
      okText={props.okText ?? "Düzenle"}
      cancelText={props.cancelText ?? "Vazgeç"}
      okButtonProps={{ loading: operationLoading }}
      onOk={() => {
        props.formInstance.validateFields().then((values) => {
          if (props.selectedId) {
            setOperationLoading(true);
            if (props.beforeOperation) {
              values = props.beforeOperation(values);
            }
            props
              .endpoint(props.selectedId, values)
              .then(() => {
                if (props.afterOperation) {
                  props.afterOperation();
                }
                props.refreshData();
              })
              .finally(() => onCancel());
          }
        });
      }}
    >
      <Form form={props.formInstance} autoComplete="off" layout="vertical">
        {props.formItems}
      </Form>
    </Modal>
  );
};

export default CrudTableEditModal;
