import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Grid,
  Layout,
  MenuProps,
  Row,
  Tooltip,
  Typography,
} from "antd";
import {
  MenuOutlined,
  SettingOutlined,
  LogoutOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { colors, heights, widths } from "../../theme";
import { useRef, useState } from "react";
import { getUserField } from "../../helpers/AuthHelper";
import ProfileUpdateModal, {
  IProfileUpdateModalRefMethods,
} from "../modal/ProfileUpdateModal";
import PasswordUpdateModal, {
  IPasswordUpdateModalRefMethods,
} from "../modal/PasswordUpdateModal";
import LogoutModal from "../modal/LogoutModal";

const { Header } = Layout;
const { Text } = Typography;

const { useBreakpoint } = Grid;

interface ICustomHeaderProps {
  collapsed?: any;
  setCollapsed?: any;
}

const CustomHeader = (props: ICustomHeaderProps) => {
  const profileUpdateModalRef = useRef<IProfileUpdateModalRefMethods>(null);
  const passwordUpdateModalRef = useRef<IPasswordUpdateModalRefMethods>(null);
  const screens = useBreakpoint();

  const [hover, setHover] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const items: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <Tooltip title="Profilimi Güncelle" arrow={false}>
          <Row align="middle" justify="center" style={{ width: 290 }}>
            <Col span={20}>
              <Row>
                <Col span={24}>
                  <Text strong>{getUserField("fullName")}</Text>
                </Col>
                <Col span={24}>
                  <Text>{getUserField("email")}</Text>
                </Col>
              </Row>
            </Col>
            <Col span={4}>
              <Row justify="center">
                <Avatar
                  style={{ backgroundColor: colors.primary }}
                  icon={<UserOutlined style={{ fontSize: 18 }} />}
                />
              </Row>
            </Col>
          </Row>
        </Tooltip>
      ),
      onClick: () => {
        profileUpdateModalRef.current?.form.setFieldsValue({
          email: getUserField("email"),
          fullName: getUserField("fullName"),
        });
        profileUpdateModalRef.current?.setOpen(true);
      },
    },
    {
      type: "divider",
    },
    {
      key: "2",
      label: (
        <Tooltip title="Şifremi Güncelle" arrow={false}>
          <Row align="middle" justify="center" style={{ padding: "8px 0" }}>
            <Col span={20}>
              <Text strong>Şifremi Güncelle</Text>
            </Col>
            <Col span={4}>
              <Row justify="center">
                <LockOutlined style={{ fontSize: 18 }} />
              </Row>
            </Col>
          </Row>
        </Tooltip>
      ),
      onClick: () => passwordUpdateModalRef.current?.setOpen(true),
    },
    {
      type: "divider",
    },
    {
      key: "4",
      label: (
        <Tooltip title="Çıkış Yap" arrow={false}>
          <Row align="middle" justify="center" style={{ padding: "8px 0" }}>
            <Col span={20}>
              <Text strong style={{ color: colors.errorColor }}>
                Çıkış Yap
              </Text>
            </Col>
            <Col span={4}>
              <Row justify="center">
                <LogoutOutlined
                  style={{ fontSize: 18, color: colors.errorColor }}
                />
              </Row>
            </Col>
          </Row>
        </Tooltip>
      ),
      onClick: () => setIsLogoutModalOpen(true),
    },
  ];

  return (
    <>
      <Header
        style={{
          padding: screens.md ? "8px 40px" : "8px 32px",
          zIndex: 2,
          backdropFilter: "blur(4px)",
          boxShadow: "rgba(19, 25, 32, 0.04) 0px 8px 24px",
          height: heights.headerHeight,
          position: "fixed",
          width: screens.md
            ? "calc(100% - " +
              (props.collapsed
                ? widths.closeSiderWidth
                : widths.openDrawerWidth) +
              "px)"
            : "100%",
        }}
      >
        <Row justify="space-between" align="middle">
          <Col>
            <Button
              onClick={() => props.setCollapsed(!props.collapsed)}
              style={{
                color: colors.black,
                height: 40,
                width: 40,
                padding: 8,
                margin: "0px 0px 0px -12px",
                backgroundColor: "#F3F5F7",
              }}
              icon={<MenuOutlined style={{ fontSize: 20 }} />}
            />
          </Col>
          <Col>
            <Dropdown menu={{ items }} trigger={["click"]} placement="bottom">
              <Button
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                shape="circle"
                style={{
                  margin: "0px -12px 0px 0px",
                  padding: 8,
                  height: 40,
                  width: 40,
                  backgroundColor: "#F3F5F7",
                }}
              >
                <SettingOutlined
                  spin={hover ? true : false}
                  style={{
                    fontSize: 20,
                    alignContent: "center",
                  }}
                />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </Header>
      <ProfileUpdateModal ref={profileUpdateModalRef} />
      <PasswordUpdateModal ref={passwordUpdateModalRef} />
      <LogoutModal
        open={isLogoutModalOpen}
        onClose={() => setIsLogoutModalOpen(false)}
      />
    </>
  );
};

export default CustomHeader;
