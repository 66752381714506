import { api } from "./Axios";
import { getCrudApi } from "./CrudApi";

export const UserCrudApi = getCrudApi("users");
export const ProductCrudApi = getCrudApi("products");
export const CouponCrudApi = getCrudApi("coupons");
export const RecordCrudApi = getCrudApi("records");
export const PaymentCrudApi = getCrudApi("payments");
export const InvoiceCrudApi = getCrudApi("invoices");
export const LabelCrudApi = getCrudApi("labels");

export const WageApi = {
  getWage: () => {
    return api.get("/wages").then((response: any) => response.data);
  },
  editWage: (values: any) => {
    return api.put("/wages", values).then((response: any) => response.data);
  },
};

export const CouponApi = {
  bulkCoupons: (values: any) => {
    return api
      .post("/coupons/bulk", values)
      .then((response: any) => response.data);
  },
};

export const ReportApi = {
  dashboardData: (userId?: string) => {
    const query = userId ? "?userId=" + userId : "";
    return api
      .get("/report/dashboard" + query)
      .then((response: any) => response.data);
  },
  pieDashboardData: (startDate: any, endDate: any, userId?: string) => {
    const query = userId ? "?userId=" + userId : "";
    return api
      .post("/report/pie" + query, { startDate: startDate, endDate: endDate })
      .then((response: any) => response.data);
  },
  barDashboardData: (startDate: any, endDate: any, userId?: string) => {
    const query = userId ? "?userId=" + userId : "";
    return api
      .post("/report/bar" + query, { startDate: startDate, endDate: endDate })
      .then((response: any) => response.data);
  },
};
