import {
  HomeOutlined,
  UserSwitchOutlined,
  LogoutOutlined,
  FileOutlined,
  SaveOutlined,
  CreditCardOutlined,
  PrinterOutlined,
  DollarOutlined,
  ProductOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Image, Typography, Grid, Drawer } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { isAdmin } from "../../helpers/AuthHelper";
import { colors, heights, widths } from "../../theme";
import { useState } from "react";
import LogoutModal from "../modal/LogoutModal";

const { useBreakpoint } = Grid;
const { Title } = Typography;
const { Sider } = Layout;

interface ISidebarProps {
  collapsed?: any;
  setCollapsed?: any;
}

const Sidebar = (props: ISidebarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const screens = useBreakpoint();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const getMenuItems = [
    {
      label: <>Dashboard</>,
      key: "/",
      icon: <HomeOutlined />,
    },
    isAdmin() && {
      label: <>Kullanıcılar</>,
      key: "/kullanicilar",
      icon: <UserSwitchOutlined />,
    },
    {
      label: <>Kuponlar</>,
      key: "/kuponlar",
      icon: <FileOutlined />,
    },
    isAdmin() && {
      label: <>Etiketler</>,
      key: "/etiketler",
      icon: <AuditOutlined />,
    },
    {
      label: <>Kayıtlar</>,
      key: "/kayitlar",
      icon: <SaveOutlined />,
    },
    {
      label: <>Ödemeler</>,
      key: "/odemeler",
      icon: <CreditCardOutlined />,
    },
    {
      label: <>Faturalar</>,
      key: "/faturalar",
      icon: <PrinterOutlined />,
    },
    isAdmin() && {
      label: <>Ürünler</>,
      key: "/urunler",
      icon: <ProductOutlined />,
    },
    isAdmin() && {
      label: <>Hakediş Ayarı</>,
      key: "/hakedis",
      icon: <DollarOutlined />,
    },
    {
      label: <p style={{ color: colors.errorColor }}>Çıkış Yap</p>,
      key: "logout",
      icon: <LogoutOutlined style={{ color: colors.errorColor }} />,
      onClick: () => setIsLogoutModalOpen(true),
    },
  ];

  const menuContent = (
    <>
      <div
        style={{
          height: heights.headerHeight,
          justifyContent: "space-between",
          display: screens.md ? "flex" : "block",
          alignItems: "center",
          padding: screens.md ? "0 16px 0 24px" : "0px 8px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div>
            <Image
              preview={false}
              src="/assets/blueLogo.png"
              style={{
                height: screens.md ? 40 : 75,
                width: screens.md ? 40 : 75,
                borderRadius: "50%",
                boxShadow: screens.md
                  ? "rgba(19, 25, 32, 0.15) 0px 8px 24px"
                  : "rgba(19, 25, 32, 0.06) 0px 8px 24px",
              }}
            />
          </div>
          <div
            style={{
              display: screens.md
                ? props.collapsed
                  ? "none"
                  : "flex"
                : "flex",
              marginLeft: 10,
              marginTop: -4,
            }}
          >
            <Title
              level={5}
              style={{
                paddingLeft: screens.md ? 20 : 0,
                width: screens.md ? 200 : 160,
                overflow: "hidden",
                margin: 0,
              }}
            >
              Sentiment Algo
            </Title>
          </div>
        </div>
      </div>
      <Menu
        selectedKeys={[location?.pathname]}
        style={{ paddingTop: 32 }}
        mode="inline"
        items={getMenuItems}
        onClick={({ key }) => {
          if (key === "logout") return;
          navigate(key);
          props.setCollapsed(false);
        }}
      />
    </>
  );

  return (
    <>
      {screens.md ? (
        <Sider
          theme="light"
          collapsible
          breakpoint="md"
          collapsed={props.collapsed}
          width={widths.openSiderWidth}
          collapsedWidth={widths.closeSiderWidth}
          onCollapse={(value) => props.setCollapsed(value)}
          style={{
            height: "100vh",
            backgroundColor: colors.white,
            boxShadow: colors.cardBoxShadow,
            zIndex: 2,
          }}
        >
          {menuContent}
        </Sider>
      ) : (
        <Drawer
          className="custom-drawer"
          placement="left"
          onClose={() => props.setCollapsed(false)}
          open={props.collapsed}
          width={widths.openDrawerWidth}
          style={{ zIndex: 2 }}
        >
          {menuContent}
        </Drawer>
      )}
      <LogoutModal
        open={isLogoutModalOpen}
        onClose={() => setIsLogoutModalOpen(false)}
      />
    </>
  );
};

export default Sidebar;
