import { api } from "./Axios";

export const getCrudApi = (entityName: string) => {
  const url = entityName;
  const urlWithSlash = url + "/";
  return {
    get: (id: number) => {
      return api.get(urlWithSlash + id).then((response: any) => {
        return response.data;
      });
    },
    getAll: (
      pagination?: boolean,
      page?: number,
      pageSize?: number,
      search?: string,
      orders?: string[],
      filters?: string[]
    ) => {
      let queries: any[] = [];
      if (pagination !== undefined)
        queries.push("pagination=" + (pagination ? "true" : "false"));
      if (page) queries.push(`page=${page}`);
      if (pageSize) queries.push(`itemsPerPage=${pageSize}`);
      if (search) queries.push(`search=${search}`);
      if (orders) queries.push(...orders);
      if (filters) queries.push(...filters);

      return api.get(url + "?" + queries.join("&")).then((response: any) => {
        return response.data;
      });
    },
    create: (entity: any) => {
      return api.post(url, entity).then((response: any) => {
        return response.data;
      });
    },
    edit: (id: number, entity: any) => {
      return api.put(urlWithSlash + id, entity).then((response: any) => {
        return response.data;
      });
    },
    delete: (id: number) => {
      return api.delete(urlWithSlash + id).then((response: any) => {
        return response.data;
      });
    },
  };
};
