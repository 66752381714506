import { Col, Form, InputNumber, Row, Select } from "antd";
import { required } from "../../helpers/FormRuleHelper";
import { wageTypeOptions } from "../../pages/WagePage";

const GeneralWage = () => {
  return (
    <Row
      gutter={[8, 8]}
      align="middle"
      style={{
        border: "1px solid rgb(19, 25, 32, 0.2)",
        backgroundColor: "rgb(238 ,238 ,238 , 0.6)",
        padding: "10px 8px 8px 8px",
        marginBottom: 16,
        borderRadius: 8,
      }}
    >
      <Col lg={8} xs={24}>
        <Form.Item
          initialValue="amount"
          name="type"
          label="Aktif Hakediş Tipi"
          rules={[required]}
        >
          <Select options={wageTypeOptions} />
        </Form.Item>
      </Col>
      <Col lg={8} xs={24}>
        <Form.Item
          name="amount"
          label="Genel Hakediş Tutarı (₺)"
          rules={[required]}
          initialValue="0"
        >
          <InputNumber
            stringMode
            precision={2}
            min={0}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
      <Col lg={8} xs={24}>
        <Form.Item
          name="rate"
          label="Genel Hakediş Oranı (%)"
          rules={[required]}
          initialValue={0}
        >
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default GeneralWage;
