import { Row } from "antd";

const CustomLoading = () => {
  return (
    <Row justify="center" align="middle" style={{ height: "100%" }}>
      <div style={{ height: 45, width: 45 }} className="table-spin" />
    </Row>
  );
};

export default CustomLoading;
