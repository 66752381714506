import {
  Button,
  Col,
  Form,
  Grid,
  Input,
  Row,
  Typography,
  Image,
  Divider,
} from "antd";
import { email, max, min, required } from "../helpers/FormRuleHelper";
import { useForm } from "antd/es/form/Form";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { saveCredentials } from "../helpers/AuthHelper";
import { AuthApi } from "../client/Api";
import { colors, defaultSources } from "../theme";
import { ISourceOptions } from "@tsparticles/engine";
import { loadFull } from "tsparticles";
import Particles, { initParticlesEngine } from "@tsparticles/react";

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

const LoginPage = () => {
  const screens = useBreakpoint();
  const [form] = useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(false);

  const handleLogin = () =>
    form.validateFields().then((values) => {
      setLoading(true);
      AuthApi.login(values.email, values.password)
        .then(({ user, accessToken, refreshToken }) => {
          saveCredentials(user, accessToken, refreshToken);
          navigate("/");
        })
        .finally(() => setLoading(false));
    });

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadFull(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesOptions: ISourceOptions = {
    autoPlay: true,
    detectRetina: true,
    fpsLimit: 120,
    fullScreen: {
      enable: false,
    },
    style: {
      height: "100%",
      width: "100%",
      position: "absolute",
      top: "0px",
      left: "0px",
    },
    interactivity: {
      detectsOn: "canvas",
      events: {
        resize: {
          delay: 0.5,
          enable: true,
        },
      },
      modes: {
        bubble: {
          distance: 400,
          duration: 2,
          opacity: 0.8,
          size: 40,
        },
      },
    },
    particles: {
      color: {
        value: ["#3ec9d6", "#ffffff", "#ffb6c1"],
      },
      number: {
        value: 120,
        density: {
          enable: true,
        },
      },
      opacity: {
        value: { min: 0.2, max: 0.8 },
        animation: {
          enable: true,
          speed: 0.5,
          sync: false,
        },
      },
      size: {
        value: { min: 2, max: 6 },
        animation: {
          enable: false,
        },
      },
      move: {
        enable: true,
        speed: 3,
        direction: "none",
        random: true,
        straight: false,
        outModes: {
          default: "out",
        },
        attract: {
          enable: true,
          rotate: {
            x: 600,
            y: 1200,
          },
        },
      },
    },
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        position: "relative",
        backgroundColor: colors.white,
      }}
    >
      <div
        style={{
          height: "56%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 2,
          backgroundColor: "rgba(64, 81, 137, 0.4)",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 2,
            backgroundColor: "rgba(64, 81, 137, 0.5)",
          }}
        >
          {init && (
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "relative",
              }}
            >
              <Particles options={particlesOptions as ISourceOptions} />
            </div>
          )}
        </div>
        <div
          style={{
            backgroundImage: "url(" + defaultSources.loginBackgroundSrc + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
            height: "100%",
            width: "100%",
          }}
        />
        <div
          style={{
            position: "absolute",
            maxHeight: "50%",
            bottom: -4,
            left: 0,
            width: "50%",
            height: screens.sm ? "100px" : "40px",
            backgroundColor: colors.white,
            zIndex: 3,
            clipPath: "polygon(0 100%, 100% 100%, 0 0)",
          }}
        >
          <div
            style={{
              position: "absolute",
              maxHeight: "100%",
              bottom: 0,
              left: 0,
              width: "100%",
              height: screens.sm ? "100px" : "40px",
              backgroundColor: colors.white,
              zIndex: 3,
              clipPath: "polygon(0 100%, 100% 100%, 0 0)",
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: -4,
            right: 0,
            width: "50%",
            height: screens.sm ? "100px" : "40px",
            backgroundColor: colors.white,
            zIndex: 3,
            clipPath: "polygon(100% 100%, 0 100%, 100% 0)",
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              width: "100%",
              height: screens.sm ? "100px" : "40px",
              backgroundColor: colors.white,
              zIndex: 3,
              clipPath: "polygon(100% 100%, 0 100%, 100% 0)",
            }}
          />
        </div>
      </div>
      <Row
        gutter={[0, 0]}
        style={{ height: "80%", width: "100%" }}
        justify="center"
      >
        <Col span={24} style={{ zIndex: 99 }}>
          <Row style={{ height: "100%" }} justify="center" align="middle">
            <Image
              src="/assets/whiteLogo.png"
              preview={false}
              style={{
                width: 200,
                height: 200,
                zIndex: 5,
                filter: "drop-shadow(0px 1px 4px #000)",
              }}
            />
          </Row>
        </Col>
        <Col
          span={24}
          style={{
            backgroundColor: colors.white,
            marginTop: 0,
            height: "fit-content",
            justifyContent: "center",
            alignContent: "center",
            maxWidth: screens.sm ? 475 : "94%",
            zIndex: 99,
            border: "1px solid #ced4da",
            borderRadius: 8,
            boxShadow: colors.cardBoxShadow,
          }}
        >
          <div style={{ padding: "1rem 1rem 1.5rem 1rem" }}>
            <Row justify="center">
              <Title level={3} style={{ margin: 0 }}>
                Hoşgeldiniz
              </Title>
            </Row>
            <Row justify="center" style={{ paddingTop: 10 }}>
              <Text style={{ textAlign: "center" }}>
                Lütfen giriş bilgilerinizi giriniz.
              </Text>
            </Row>
            <Divider />
            <Form form={form} layout="vertical" onFinish={handleLogin}>
              <Form.Item
                label="E-Posta"
                name="email"
                rules={[required, email, max(255)]}
              >
                <Input placeholder="E-Posta giriniz" />
              </Form.Item>
              <Form.Item
                label="Şifre"
                name="password"
                rules={[required, min(4), max(255)]}
              >
                <Input.Password placeholder="Şifre giriniz" />
              </Form.Item>
              <Button type="primary" loading={loading} htmlType="submit" block>
                Giriş Yap
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LoginPage;
