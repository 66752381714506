import { useEffect, useState } from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import { ConstantContext } from "../../contexts";
import { GeneralApi } from "../../client/Api";
import Sidebar from "./Sidebar";
import { colors, heights } from "../../theme";
import CustomHeader from "./CustomHeader";

const { Content } = Layout;

const AdminLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [constants, setConstants] = useState<any>();

  useEffect(() => {
    GeneralApi.getConstants().then((response) => setConstants(response));
  }, []);

  return (
    <ConstantContext.Provider value={constants}>
      <Layout style={{ minHeight: "100vh" }}>
        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
        <Layout style={{ backgroundColor: colors.white }}>
          <CustomHeader collapsed={collapsed} setCollapsed={setCollapsed} />
          <Content
            style={{
              height:
                "calc(100vh - " +
                (heights.headerHeight + heights.contentPaddingHeight * 2) +
                "px)",
              paddingTop: heights.headerHeight,
              minHeight: 1,
              overflow: "auto",
              zIndex: 1,
              backgroundColor: colors.white,
            }}
          >
            <div style={{ padding: heights.contentPaddingHeight }}>
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    </ConstantContext.Provider>
  );
};

export default AdminLayout;
