import { Form, FormInstance, Modal, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { CouponApi, ProductCrudApi } from "../../client/EntityApi";
import { getOptionsFromEntities } from "../../helpers/UtilHelper";
import { getCouponCPFormItems } from "../hook/CouponCPComponent";
import { ConstantContext } from "../../contexts";
import CouponOptionalCodeForm from "../form/CouponOptionalCodeForm";

export interface ICouponAddModalRefMethods {
  setOpen: (open: boolean) => void;
  form: FormInstance;
}

export interface ICouponAddModalProps {
  afterCreate?: () => void;
}

const CouponAddModal = forwardRef((props: ICouponAddModalProps, ref) => {
  const constants = useContext(ConstantContext);
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [productOptions, setProductOptions] = useState([]);

  useEffect(() => {
    ProductCrudApi.getAll(false).then((response) => {
      setProductOptions(getOptionsFromEntities(response["hydra:member"]));
    });
  }, []);

  useImperativeHandle(ref, () => ({
    setOpen: setOpen,
    form: form,
  }));

  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Kupon Oluştur"
      open={open}
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={() =>
        form.validateFields().then((values) => {
          setLoading(true);
          CouponApi.bulkCoupons(values)
            .then((response) => {
              message.success(response.message);
              props.afterCreate && props.afterCreate();
              onCancel();
            })
            .finally(() => setLoading(false));
        })
      }
      cancelText="Vazgeç"
      okText="Oluştur"
    >
      <Form form={form} layout="vertical">
        <CouponOptionalCodeForm form={form} />
        {getCouponCPFormItems(productOptions, constants, form)}
      </Form>
    </Modal>
  );
});

export default CouponAddModal;
