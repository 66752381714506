import { Col, Grid, Row } from "antd";
import { ReactNode } from "react";

interface ICrudTableTitleRowProps {
  firstCol?: ReactNode;
  secondCol?: ReactNode;
  thirdCol?: ReactNode;
}

const { useBreakpoint } = Grid;

const CrudTableTitleRow = (props: ICrudTableTitleRowProps) => {
  const screens = useBreakpoint();

  return (
    <Row
      gutter={[8, 8]}
      align="middle"
      style={{
        paddingBottom: 8,
        flexWrap: "wrap",
        margin: "0 -24px",
      }}
    >
      <Col xs={12} md={12} lg={8} style={{ order: screens.lg ? "" : 1 }}>
        <Row style={{ height: "100%" }} align="middle">
          {props.firstCol}
        </Row>
      </Col>
      <Col xs={24} md={24} lg={8} style={{ order: screens.lg ? "" : 3 }}>
        <Row justify="center">{props.secondCol}</Row>
      </Col>
      <Col xs={12} md={12} lg={8} style={{ order: screens.lg ? "" : 2 }}>
        <Row justify="end" align="middle">
          {props.thirdCol}
        </Row>
      </Col>
    </Row>
  );
};

export default CrudTableTitleRow;
