import { Skeleton } from "antd";
import { colors } from "../../theme";
import CustomCard from "./CustomCard";

interface IChartCardProps {
  children?: any;
  title?: any;
  extra?: any;
  actions?: any;
  loading?: any;
}

const ChartCard = (props: IChartCardProps) => {
  return (
    <CustomCard title={props.title} extra={props.extra} actions={props.actions}>
      <div
        style={{
          width: "100%",
          height: "500px",
          maxHeight: "500px",
          backgroundColor: colors.white,
        }}
      >
        <Skeleton loading={props.loading} active>
          {props.children}
        </Skeleton>
      </div>
    </CustomCard>
  );
};

export default ChartCard;
