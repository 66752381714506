import { Form, FormInstance, Input, Select, Tag } from "antd";
import { email, max, min, required } from "../../helpers/FormRuleHelper";
import { createToken, getConstantValue } from "../../helpers/UtilHelper";
import CrudTableProcessButton from "../crud/CrudTableProcessButton";
import { KeyOutlined } from "@ant-design/icons";

export const getUserCPColumns = (constants: any) => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    { title: "İsim", dataIndex: "fullName", key: "fullName" },
    {
      title: "T. Kayıt Sayısı",
      dataIndex: "recordCount",
      key: "recordCount",
    },
    {
      title: "T. Tıklanma Sayısı",
      dataIndex: "clickCount",
      key: "clickCount",
    },
    {
      title: "T. Bakiye",
      dataIndex: "balance",
      key: "balance",
      render: (value: any) => value + " ₺",
    },
    { title: "E-Posta", dataIndex: "email", key: "email" },
    {
      title: "Aktif mi?",
      dataIndex: "isActive",
      key: "isActive",
      filters: constants?.yesNo,
      filterMultiple: false,
      render: (value: boolean) => (
        <Tag color={value ? "green" : "red"}>{value ? "Evet" : "Hayır"}</Tag>
      ),
    },
    {
      title: "Rol",
      dataIndex: "roles",
      key: "roles",
      filters: constants?.roles,
      render: (value: any) =>
        value.map((role: any, index: number) => (
          <Tag key={index}>{getConstantValue(role, constants?.roles)}</Tag>
        )),
    },
  ];
};

export const getUserCPFormItems = (
  roles: any[],
  form?: FormInstance,
  isEdit = false
) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        name="fullName"
        label="İsim Soyisim"
        rules={[required, max(255)]}
      >
        <Input placeholder="İsim Soyisim" />
      </Form.Item>
      <Form.Item
        name="email"
        label="E-Posta"
        rules={[required, email, max(255)]}
      >
        <Input placeholder="E-Posta giriniz." />
      </Form.Item>
      {isEdit ? (
        <Form.Item
          name="plainPassword"
          label="Şifre"
          rules={[min(4), max(255)]}
        >
          <Input.Password
            placeholder="Şifre değiştirmek istemiyorsanız boş bırakın."
            prefix={
              <CrudTableProcessButton
                tooltipText="Otomatik Şifre Oluştur"
                icon={<KeyOutlined />}
                onClick={() =>
                  form?.setFieldValue("plainPassword", createToken())
                }
              />
            }
          />
        </Form.Item>
      ) : (
        <Form.Item
          name="plainPassword"
          label="Şifre"
          rules={[required, min(4), max(255)]}
        >
          <Input.Password
            placeholder="Şifre giriniz."
            prefix={
              <CrudTableProcessButton
                tooltipText="Otomatik Şifre Oluştur"
                icon={<KeyOutlined />}
                onClick={() =>
                  form?.setFieldValue("plainPassword", createToken())
                }
              />
            }
          />
        </Form.Item>
      )}
      <Form.Item
        name="isActive"
        label="Aktif mi?"
        rules={[required]}
        initialValue={true}
      >
        <Select
          options={[
            { label: "Evet", value: true },
            { label: "Hayır", value: false },
          ]}
        />
      </Form.Item>
      <Form.Item
        name="roles"
        label="Rol"
        rules={[required]}
        initialValue="ROLE_USER"
      >
        <Select options={roles} />
      </Form.Item>
    </>
  );
};
