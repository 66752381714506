import { useState } from "react";
import { Col, Grid, Row, Tooltip, Typography } from "antd";
import { colors } from "../../theme";

const { Text } = Typography;
const { useBreakpoint } = Grid;

interface ICouponCardProps {
  hoveredIndex?: any;
  bgColor?: any;
  couponCode?: any;
  clickCount?: any;
  registerCount?: any;
  payment?: any;
  limit?: any;
  expirationDate?: any;
}

const CouponCard = (props: ICouponCardProps) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const screens = useBreakpoint();
  const hexToRgba = (hex: string, opacity: number) => {
    const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  return (
    <Col
      lg={8}
      xs={24}
      onMouseEnter={() => setHoveredIndex(props.hoveredIndex)}
      onMouseLeave={() => setHoveredIndex(null)}
      style={{ display: "grid" }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          background: "url(/assets/couponBg.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          transform: "rotate(180deg)",
          borderRadius: 8,
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        <Row
          gutter={[0, 8]}
          style={{
            height: "100%",
            backgroundColor: hexToRgba(props.bgColor, 0.6),
            padding: 24,
            borderRadius: 8,
            transform: "rotate(180deg)",
            opacity: hoveredIndex === props.hoveredIndex ? 1 : 0.8,
          }}
        >
          <Col span={24}>
            <Text
              style={{
                color: colors.white,
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              Kupon Kodu: {props.couponCode}
            </Text>
          </Col>
          <Col span={24}>
            <Text
              style={{
                color: colors.white,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {props.clickCount} Tıklanma / {props.registerCount} Kayıt
            </Text>
          </Col>
          <Col span={24}>
            <Text
              style={{
                color: colors.white,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              Hakediş Tutarı:{" "}
              <Text
                style={{
                  color: colors.white,
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                {props.payment} ₺
              </Text>
            </Text>
          </Col>
          <Col xl={12} lg={24} xs={24}>
            <Text
              style={{
                color: colors.white,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              Kalan Limit:{" "}
              <Text
                style={{
                  color: colors.white,
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                {props.limit}
              </Text>
            </Text>
          </Col>
          <Col xl={12} lg={24} xs={24}>
            <Row justify={screens.xl ? "end" : "start"}>
              <Tooltip title="Kuponu Son Kullanma Tarihi">
                <Text
                  style={{
                    color: colors.white,
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  SKT:{" "}
                  <Text
                    style={{
                      color: colors.white,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    {props.expirationDate}
                  </Text>
                </Text>
              </Tooltip>
            </Row>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default CouponCard;
