import { Card, Typography } from "antd";

const { Title } = Typography;

interface ICustomCardProps {
  title?: any;
  extra?: any;
  subTitle?: any;
  children?: any;
  bordered?: any;
  hoverable?: any;
  actions?: any;
  tabList?: any;
  activeTabKey?: any;
  onTabChange?: any;
  style?: any;
  className?: any;
  customCardProps?: any;
}

const CustomCard = (props: ICustomCardProps) => {
  return (
    <div
      style={{
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        boxShadow: "rgba(19, 25, 32, 0.08) 0px 8px 24px",
        borderRadius: 12,
      }}
    >
      <Card
        title={props.title}
        extra={props.extra}
        bordered={props.bordered}
        hoverable={props.hoverable}
        actions={props.actions}
        tabList={props.tabList}
        activeTabKey={props.activeTabKey}
        onTabChange={props.onTabChange}
        style={props.style}
        className={props.className}
        {...props.customCardProps}
      >
        {props.subTitle && (
          <Title
            level={5}
            style={{ color: "rgb(91, 107, 121)", margin: "0px 0px 12px" }}
          >
            {props.subTitle}
          </Title>
        )}
        {props.children}
      </Card>
    </div>
  );
};

export default CustomCard;
