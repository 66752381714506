import { api } from "./Axios";

export const GeneralApi = {
  getConstants: () => {
    return api.get("constants").then((response: any) => response.data);
  },
};

export const AuthApi = {
  login: (email: string, password: string) => {
    return api
      .post("login", { username: email, password: password })
      .then((response: any) => response.data);
  },
  register: (values: any) => {
    return api.post("register", values).then((response: any) => response.data);
  },
  refreshToken: (refreshToken: string) => {
    return api
      .post("refresh-token", { refreshToken: refreshToken })
      .then((response: any) => response.data);
  },
  profileUpdate: (values: any) => {
    return api.put("profile", values).then((response: any) => response.data);
  },
  renewPassword: (values: any) => {
    return api
      .put("renew-password", values)
      .then((response: any) => response.data);
  },
};
