import { AliasToken } from "antd/es/theme/internal";
import { ThemeConfig } from "antd";

export const defaultSources = {
  bluelogoSrc: "/assets/blueLogo.png",
  whitelogoSrc: "/assets/whiteLogo.png",
  loginBackgroundSrc: "/assets/login-bg.jpg",
};

export const heights = {
  headerHeight: 75,
  contentPaddingHeight: 24,
};

export const widths = {
  openSiderWidth: 280,
  closeSiderWidth: 90,
  openDrawerWidth: 280,
};

export const colors = {
  primary: "#1d6cfe",
  white: "white",
  black: "black",
  infoColor: "#3ec9d6",
  warningColor: "#e58a00",
  errorColor: "#dc2626",
  secondaryColor: "#3e4853",
  successColor: "#2ca87f",
  cardBoxShadow: "rgba(19, 25, 32, 0.08) 0px 8px 24px",
};

export const seedToken: Partial<AliasToken> = {
  colorPrimary: colors.primary,
  fontFamily: '"TRTMedium", sans-serif',
  colorWarning: colors.warningColor,
  colorError: colors.errorColor,
  colorSuccess: colors.successColor,
  colorInfo: colors.infoColor,
  colorTextBase: colors.black,
  fontSize: 14,
  borderRadius: 8,
};

export const componentsToken: ThemeConfig["components"] = {
  Layout: {
    headerHeight: heights.headerHeight,
    bodyBg: "rgb(255, 255, 255)",
    headerBg: "rgba(255, 255, 255, 0.8)",
  },
  Button: {
    paddingBlock: 6,
    paddingInline: 16,
    borderRadius: 8,
  },
  Menu: {
    iconSize: 22,
    collapsedIconSize: 22,
    itemHeight: 46,
    motionDurationSlow: "0",
    motionDurationMid: "0",
    itemMarginBlock: 2,
    activeBarBorderWidth: 0,
  },
  Tooltip: {
    colorBgSpotlight: "rgb(97, 97, 97)",
  },
  Modal: {
    borderRadiusLG: 12,
    boxShadow: "rgba(19, 25, 32, 0.08) 0px 8px 24px",
    colorPrimaryBorder: "rgb(219, 224, 229)",
    colorText: "rgb(29, 38, 48)",
  },
  Pagination: {
    itemSize: 30,
    borderRadius: 8,
  },
  Table: {
    lineHeight: 1.5,
    headerSplitColor: "rgb(219, 224, 229)",
    fontSize: 12,
    fontWeightStrong: 600,
    borderRadius: 12,
    headerBorderRadius: 0,
    cellPaddingInline: 24,
    cellPaddingBlock: 12,
    cellFontSize: 14,
    fontSizeIcon: 10,
    headerBg: "rgb(248, 249, 250)",
    rowSelectedHoverBg: "rgb(250, 250, 250)",
    colorText: "rgb(91, 107, 121)",
    colorTextHeading: colors.black,
    bodySortBg: "rgba(250, 250, 250, 0)",
  },
};
