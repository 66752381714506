import { Form, Input, InputNumber, Select } from "antd";
import {
  getConstantValue,
  getFormattedDate,
  getRemoteFileUrl,
} from "../../helpers/UtilHelper";
import { required } from "../../helpers/FormRuleHelper";
import { Link } from "react-router-dom";
import { isAdmin } from "../../helpers/AuthHelper";
import ChunkUpload, { getValueFromEvent } from "../common/ChunkUpload";

export const getInvoiceCPColumns = (invoiceStates: any[]) => {
  let columns = [
    { title: "No", dataIndex: "id", key: "id" },
    {
      title: "Dosya",
      dataIndex: "file",
      key: "file",
      render: (value: any) =>
        value.uid && (
          <Link target="_blank" to={getRemoteFileUrl(value.uid)}>
            {value.name}
          </Link>
        ),
    },
    {
      title: "Fatura Tutarı",
      dataIndex: "amount",
      key: "amount",
      sorter: true,
      render: (value: any) => value + " ₺",
    },
    {
      title: "Durum",
      dataIndex: "state",
      key: "state",
      filters: invoiceStates,
      render: (value: any) => getConstantValue(value, invoiceStates),
    },
    {
      title: "Oluşturulma Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (value: any) => getFormattedDate(value, "DD.MM.YYYY HH:mm"),
    },
  ];

  if (isAdmin()) {
    columns.splice(1, 0, {
      title: "Kullanıcı",
      dataIndex: "user",
      key: "user",
      render: (value: any) => value.fullName,
    });
  }

  return columns;
};

export const getInvoiceCPAddFormItems = () => {
  return (
    <>
      <Form.Item name="amount" label="Fatura Tutarı" rules={[required]}>
        <InputNumber
          stringMode
          precision={2}
          min={1}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        label="Fatura"
        name="media"
        valuePropName="fileList"
        getValueFromEvent={getValueFromEvent}
        rules={[required]}
      >
        <ChunkUpload
          multiple={false}
          accept=".jpeg, .JPEG, .jpg, .JPG, .png, .PNG, .pdf, .PDF"
          style={{ marginBottom: 32 }}
        />
      </Form.Item>
    </>
  );
};

export const getInvoiceCPEditFormItems = (invoiceStates: any[]) => {
  return (
    <>
      <Form.Item name="id" rules={[required]} hidden>
        <Input />
      </Form.Item>
      <Form.Item name="state" label="Durum" rules={[required]}>
        <Select options={invoiceStates} />
      </Form.Item>
      <Form.Item name="amount" label="Fatura Tutarı" rules={[required]}>
        <InputNumber
          stringMode
          precision={2}
          min={1}
          style={{ width: "100%" }}
        />
      </Form.Item>
    </>
  );
};
