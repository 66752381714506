import { DatePicker } from "antd";

const ZDatePicker = (props: any) => {
  return (
    <DatePicker
      {...props}
      style={{ width: "100%" }}
      showTime={props.showTime}
      format={props.showTime ? "DD.MM.YYYY HH:mm:ss" : "DD.MM.YYYY"}
    />
  );
};

export default ZDatePicker;
