import { Form, InputNumber, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { required } from "../../helpers/FormRuleHelper";

interface ICouponAgainUseTypeFormProps {
  againUseTypes?: any[];
  form?: FormInstance;
}

const CouponAgainUseTypeForm = (props: ICouponAgainUseTypeFormProps) => {
  const againUseType = Form.useWatch("againUseType", props.form);

  return (
    <>
      <Form.Item
        name="againUseType"
        label="Tekrar Kullanım Tipi"
        rules={[required]}
        initialValue="always"
      >
        <Select options={props.againUseTypes} />
      </Form.Item>
      {againUseType === "period" && (
        <Form.Item
          name="againUseValue"
          label="Tekrar Kullanım Değeri"
          rules={[required]}
        >
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>
      )}
    </>
  );
};

export default CouponAgainUseTypeForm;
