import { Alert, Form, FormInstance, Input } from "antd";
import { max, min, required } from "../../helpers/FormRuleHelper";
import SearchSelect from "../common/SearchSelect";
import { getOptionsFromEntities } from "../../helpers/UtilHelper";
import { UserCrudApi } from "../../client/EntityApi";

interface ICouponOptionalCodeFormProps {
  form?: FormInstance;
}

const CouponOptionalCodeForm = (props: ICouponOptionalCodeFormProps) => {
  const users = Form.useWatch("users", props.form);

  return (
    <>
      <Form.Item name="users" label="Kullanıcılar" rules={[required]}>
        <SearchSelect
          multiple
          searchAction={(searchValue, setOptions, setLoading) => {
            return UserCrudApi.getAll(true, 1, 10, searchValue)
              .then((response) => {
                setOptions(
                  getOptionsFromEntities(
                    response["hydra:member"],
                    "fullName",
                    "id"
                  )
                );
              })
              .finally(() => setLoading(false));
          }}
        />
      </Form.Item>
      {users?.length === 1 && (
        <Form.Item name="code" label="Kupon Kodu" rules={[min(2), max(255)]}>
          <Input placeholder="Kupon kodu giriniz" />
        </Form.Item>
      )}
      {users?.length > 1 && (
        <Form.Item>
          <Alert
            style={{}}
            type="warning"
            message="Birden fazla kullanıcı seçtiniz. Kupon kodları otomatik oluşturulacaktır."
          />
        </Form.Item>
      )}
    </>
  );
};

export default CouponOptionalCodeForm;
