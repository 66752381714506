import {
  getFormattedDate,
  getOptionsFromEntities,
} from "../../helpers/UtilHelper";
import { max, required } from "../../helpers/FormRuleHelper";
import { Form, Input, InputNumber } from "antd";
import SearchSelect from "../common/SearchSelect";
import { UserCrudApi } from "../../client/EntityApi";
import { isAdmin } from "../../helpers/AuthHelper";

const { TextArea } = Input;

export const getPaymentCPColumns = () => {
  let columns = [
    { title: "No", dataIndex: "id", key: "id" },
    {
      title: "Ödeme Tutarı",
      dataIndex: "amount",
      key: "amount",
      sorter: true,
      render: (value: any) => value + " ₺",
    },
    {
      title: "Oluşturulma Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (value: any) => getFormattedDate(value, "DD.MM.YYYY HH:mm"),
    },
  ] as any[];

  if (isAdmin()) {
    columns.splice(1, 0, {
      title: "Kullanıcı",
      dataIndex: "user",
      key: "user",
      render: (value: any) => value.fullName,
    });
  }

  return columns;
};

export const getPaymentCPFormItems = (isEdit = false) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      {!isEdit && (
        <Form.Item name="user" label="Kullanıcı" rules={[required]}>
          <SearchSelect
            searchAction={(searchValue, setOptions, setLoading) =>
              UserCrudApi.getAll(true, 1, 10, searchValue)
                .then((response) => {
                  setOptions(
                    getOptionsFromEntities(response["hydra:member"], "fullName")
                  );
                })
                .finally(() => setLoading(false))
            }
          />
        </Form.Item>
      )}
      {getPaymentDefaultFormItems()}
    </>
  );
};

export const getPaymentDefaultFormItems = () => {
  return (
    <>
      <Form.Item name="amount" label="Ödeme Tutarı" rules={[required]}>
        <InputNumber
          stringMode
          precision={2}
          min={0}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item name="description" label="Ödeme Açıklaması" rules={[max(500)]}>
        <TextArea rows={2} />
      </Form.Item>
    </>
  );
};
